@font-face {
    font-family: 'ms_sans_serif';
    src: url('react95/dist/fonts/ms_sans_serif.woff2') format('truetype');
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: 'ms_sans_serif';
    src: url('react95/dist/fonts/ms_sans_serif_bold.woff2') format('truetype');
    font-weight: bold;
    font-style: normal
}
